<template>
    <!-- 密码设置 -->
    <div class="setpwd_box">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
            <el-form-item>
                <span style="color:red;font-size:14px;">* 密码只能输入小写字母、数字、下划线</span>
            </el-form-item>
            <el-form-item label="原密码" prop="OldPassword">
                <el-input v-model="ruleForm.OldPassword" onkeyup="this.value=this.value.replace(/[^a-z0-9_]/g,'')"
                    placeholder="请输入原密码" :type="pwdtype" maxlength="20"></el-input>
                <i v-if="pwdstatus" class="iconfont" @click.prevent="lookpwdbtn(false)">&#xe61b;</i>
                <i v-else class="iconfont" @click.prevent="lookpwdbtn(true)">&#xe61c;</i>
            </el-form-item>
            <el-form-item label="新密码" prop="NewPassword">
                <el-input v-model="ruleForm.NewPassword" onkeyup="this.value=this.value.replace(/[^a-z0-9_]/g,'')"
                    placeholder="请输入新密码" :type="pwdtype" maxlength="20"></el-input>
                <i v-if="pwdstatus" class="iconfont" @click.prevent="lookpwdbtn(false)">&#xe61b;</i>
                <i v-else class="iconfont" @click.prevent="lookpwdbtn(true)">&#xe61c;</i>
            </el-form-item>
            <el-form-item label="再次确认密码" prop="surepassword">
                <el-input v-model="ruleForm.surepassword" onkeyup="this.value=this.value.replace(/[^a-z0-9_]/g,'')"
                    placeholder="请再次确认密码" :type="pwdtype" maxlength="20"></el-input>
                <i v-if="pwdstatus" class="iconfont" @click.prevent="lookpwdbtn(false)">&#xe61b;</i>
                <i v-else class="iconfont" @click.prevent="lookpwdbtn(true)">&#xe61c;</i>
            </el-form-item>
            <el-form-item style="text-align:right;">
                <span class="getpwd" @click.prevent="forgetpwd()">忘记原密码？点击找回</span>
            </el-form-item>
            <el-form-item style="text-align: center;">
                <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
                <el-button type="info" @click.prevent="clearForm('ruleForm')">清空</el-button>
            </el-form-item>
        </el-form>
        <!-- 忘记密码 -->
        <ForgetPwd ref="refforgetpwd"></ForgetPwd>
    </div>
</template>
<script>
import { Message } from 'element-ui';
import API from '@/api/config'
import ForgetPwd from "../../components/forgetpwd/forgetpwd.vue";
import { removeStore } from '../../../utils/utils';
export default {
    components: {
        ForgetPwd
    },
    data() {
        return {
            pwdtype: 'password',
            pwdstatus: true,
            ruleForm: {
                OldPassword: '',
                NewPassword: '',
                surepassword: ''
            },
            rules: {
                OldPassword: [
                    { required: true, message: '请输入原密码', trigger: 'blur' }
                ],
                NewPassword: [
                    { required: true, message: '请输入新密码', trigger: 'blur' }
                ],
                surepassword: [
                    { required: true, message: '请再次确认密码', trigger: 'blur' }
                ],
            }
        }
    },
    methods: {
        //保存
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.ruleForm.NewPassword.length < 6) {
                        Message({ type: 'error', message: '密码至少6位！' }); return false;
                    }
                    if (this.ruleForm.NewPassword != this.ruleForm.surepassword) {
                        Message({ type: 'error', message: '两次密码输入不一致！' }); return false;
                    }
                    this.$confirm('确定提交密码？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        API.Web.UpdatePassword(this.ruleForm).then(res => {
                            if (res.Code === 0) {
                                Message({ type: 'success', message: '密码设置成功！' });

                                setTimeout(() => {
                                    this.$store.dispatch('user/loginOut')
                                }, 300)

                            } else {
                                Message({type: 'error', message: res.Message})
                            }
                        });
                    }).catch(() => {
                    });
                } else {
                    return false;
                }
            });
        },
        //清空
        clearForm(formName) {
            this.$refs[formName].resetFields();
        },
        //密码显示切换
        lookpwdbtn(b) {
            this.pwdstatus = b;
            if (b) {
                this.pwdtype = "password";
            }
            else {
                this.pwdtype = "text";
            }
        },
        //找回密码
        forgetpwd() {
            this.$refs.refforgetpwd.layerclass = 'layershow';
            this.$refs.refforgetpwd.layerboxclass = "layerboxshow";
            this.$refs.refforgetpwd.forgetpwdshow = true;
        },
    }
}
</script>
<style lang="less" scoped>
.setpwd_box {
    margin: 10px;
    border: 1px solid #e4e4e4;
    padding: 20px 0 0;
}
</style>
<style lang="less">
.setpwd_box .el-form .el-form-item {
    max-width: 80%;
    min-width: 400px;
}

.setpwd_box .el-form .el-form-item i {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 30px;
    text-align: center;
    font-size: 16px;
    color: #666;
    user-select: none;
}

.setpwd_box .el-form .el-form-item .getpwd {
    text-decoration: underline;
    color: #666;
    font-size: 14px;
    display: inline-block;
    user-select: none;
}

.setpwd_box .el-form .el-form-item .getpwd:hover {
    color: rgb(var(--themecolor));
    cursor: pointer;
}

.setpwd_box .el-form .el-form-item i:hover {
    cursor: pointer;
    color: rgb(var(--themecolor));
}

.setpwd_box .el-form .el-form-item .el-button--primary {
    width: 120px;
    background-color: rgba(var(--themecolor), 0.85);
    border-color: rgba(var(--themecolor), 0.85);
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
}

.setpwd_box .el-form .el-form-item .el-button--primary:hover {
    background-color: rgba(var(--themecolor), 1);
    border-color: rgba(var(--themecolor), 1);
}
.setpwd_box .el-form .el-form-item .el-input__inner:focus{
    border-color: #ddd;
}
.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button:focus, .el-message-box__wrapper .el-message-box .el-message-box__btns .el-button:hover {
  color: rgba(var(--themecolor),0.75) !important;
  border-color: rgba(var(--themecolor),0.35) !important;
  background-color: rgba(var(--themecolor),0.01) !important;
}
.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button--primary{
    background-color: rgba(var(--themecolor),0.75) !important;
    border-color: rgba(var(--themecolor),0.75) !important;
}
.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button--primary:focus,.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button--primary:hover{
    background-color: rgba(var(--themecolor),0.95) !important;
    border-color: rgba(var(--themecolor),0.95) !important;
    color: #fff !important;
}
.el-message-box__wrapper .el-message-box .el-message-box__header .el-message-box__headerbtn .el-message-box__close:hover{
    color: rgba(var(--themecolor),0.95) !important;
}
</style>